import { RefObject, useEffect, useState } from 'react';

export const useIsOverflow = (ref: RefObject<HTMLElement>): boolean => {
  const [isOverflow, setIsOverflow] = useState(false);
  useEffect(() => {
    if (ref.current?.scrollWidth && ref.current?.clientWidth) {
      setIsOverflow(ref.current?.scrollWidth > ref.current?.clientWidth);
    }
  }, [ref]);

  return isOverflow;
};
