import { array, object } from 'yup';
import { OptionalObjectSchema } from 'yup/lib/object';

import { ProductGroup } from '@lib/offers/types';
import { SchemaChild } from '@lib/validation/types';

export const getSchema = (
  groups: ProductGroup[],
): OptionalObjectSchema<SchemaChild> => {
  const shape = groups.reduce<SchemaChild>(
    (acc, group) => ({ ...acc, ...mapGroup(group) }),
    {},
  );

  return object<typeof shape>(shape);
};

function mapGroup({ productGroupId, rule }: ProductGroup) {
  const baseSchema = array()
    .min(rule.from, ({ min }) => ({
      key: 'common:validation.array.min',
      values: { count: min },
    }))
    .max(rule.to, ({ max }) => ({
      key: 'common:validation.array.max',
      values: { count: max },
    }));

  if (rule.type === 'MUST') {
    return {
      [productGroupId]: baseSchema.required(() => ({
        key: 'common:validation.array.min',
        values: { count: rule.from },
      })),
    };
  }

  if (rule.type === 'CAN') {
    return {
      [productGroupId]: array().when({
        is: (value) => Array.isArray(value) && value.length > 0,
        then: baseSchema,
      }),
    };
  }
}
