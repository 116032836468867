import { FC } from 'react';

import { PriceCaseProduct } from '@lib/offers/types';
import { SxStyles } from '@lib/theme/sxTheme';
import { Box, Stack } from '@mui/material';
import { ImageContainer } from '@templates/purchase/common/select-parent-product-step/parent-product-list/parent-product-card/ImageContainer';
import { TextLines } from '@templates/purchase/common/select-parent-product-step/parent-product-list/parent-product-card/TextLines';
import { Price } from '@ui/price/Price';

import { ProductName } from '../ProductName';

interface Props {
  product: PriceCaseProduct;
}

export const ProductBox: FC<Props> = ({ product }) => {
  return (
    <Stack sx={styles.getValue('root')}>
      <ImageContainer image={product.image} />
      <Box sx={{ mb: 3 }}>
        <ProductName name={product.commercialName} />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'left' }}>
        <Price pricing={product.productPricing} />
      </Box>
      <TextLines tags={product.tags?.filter((tag) => tag.label)} />
    </Stack>
  );
};

const styles = new SxStyles({
  root: {
    borderRadius: '16px',
    border: '1px solid #E6E6E6',
    height: '100%',
    width: '100%',
    padding: '12px',
    textAlign: 'left',
  },
});
