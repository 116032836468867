import { FC } from 'react';

import { YupError } from '@lib/form/types';
import { ProductGroup } from '@lib/offers/types';
import { SxStyles } from '@lib/theme/sxTheme';
import { Box } from '@mui/material';
import { DefaultCard } from '@ui/cards/default-card/DefaultCard';
import { ErrorText } from '@ui/form/ErrorText';

import { ProductBox } from './ProductBox';

interface Props {
  productGroup: ProductGroup;
  error: YupError | undefined;
  minColumnWidth?: number;
}

export const ProductGroupBox: FC<Props> = ({
  error,
  productGroup,
  minColumnWidth = 240,
}) => {
  const { productGroupId, name, products } = productGroup;
  const dynamicStyles = styles({ hasError: !!error, minColumnWidth });

  return (
    <Box>
      <ErrorText error={error ?? false} />
      <DefaultCard
        title={name}
        contentSx={dynamicStyles.getValue('boxContent')}
        contentBackgroundColor="background.paper"
      >
        <Box sx={dynamicStyles.getValue('gridContainer')}>
          {products.map((product, index) => (
            <Box key={index}>
              <ProductBox product={product} productGroupId={productGroupId} />
            </Box>
          ))}
        </Box>
      </DefaultCard>
    </Box>
  );
};

interface StyleProps {
  hasError: boolean;
  minColumnWidth: number;
}

const styles = ({ hasError, minColumnWidth }: StyleProps) =>
  new SxStyles({
    boxContent: {
      borderColor: hasError ? 'error.main' : undefined,
      borderWidth: hasError ? 1 : undefined,
      borderStyle: hasError ? 'solid' : undefined,
    },
    gridContainer: {
      display: 'grid',
      gridTemplateColumns: `repeat(auto-fill, minmax(${minColumnWidth}px, 1fr))`,
      gap: (theme) => theme.spacing(2),
      gridAutoRows: '1fr',
    },
  });
