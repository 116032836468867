import { FC, useEffect, useMemo } from 'react';
import { NoDataPlaceholder } from '@ui/placeholders/NoDataPlaceholder';
import { Box, Stack } from '@mui/material';
import { BundleBox } from '@templates/purchase/common/addons-step/addons-step-content/bundle-box/BundleBox';
import { ProductGroupBox } from '@templates/purchase/common/addons-step/addons-step-content/product-group-box/ProductGroupBox';
import { usePurchaseInfo } from '@templates/purchase/common/PurchaseInfoContext';
import { useAuth } from '@lib/auth/AuthContext';
import { getSchema } from '@templates/purchase/common/addons-step/schema';
import { useSchemaValidation } from '@lib/validation/useSchemaValidation';
import { useScrollToRef } from '@lib/scroll/useScrollToRef';
import { useApiMutation } from '@lib/fetch/useApiMutation';
import { useTranslation } from 'next-i18next';
import { getEnv } from '@lib/env/getEnv';
import { useTenantProps } from '@lib/tenants/TenantPropsContext';
import { api } from '@api/index';
import { Button } from '@ui/buttons/Button';
import {
  OrderType,
  PostSubmitOrderRo,
  ProductGroup,
} from '@api/ocb-digital/order/types';
import { mapProductGroups } from '@templates/purchase/common/helpers';
import { useRouter } from 'next/router';
import { withQueryParams } from '@lib/route/queryParams';
import { usePathname } from 'next/navigation';
import { APP_ROUTES } from '@lib/route/constants';
import { generateSourceId } from '@lib/helpers/utils';
import { ParentProduct } from '@lib/offers/types';
import { ParentProductRo } from '@api/ocb-digital/offer/types';
import { Step } from '@templates/purchase/common/types';

interface Props {
  parentProduct: ParentProduct | null;
}

export const ProductAddonsContent: FC<Props> = ({ parentProduct }) => {
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth();
  const { tenant } = useTenantProps();
  const router = useRouter();
  const pathname = usePathname();
  const env = getEnv();
  const tenantId = tenant.config.setup.id[env.client.appEnv];
  const { selectedProducts, selectedParentProduct, setSelectedParentProduct } =
    usePurchaseInfo();
  const schema = useMemo(
    () => getSchema(parentProduct?.childProductGroups ?? []),
    [parentProduct?.childProductGroups],
  );
  const { errors, validate } = useSchemaValidation(schema);
  const { scrollRef, scrollToRef } = useScrollToRef();
  const onSubmitAddon = useApiMutation(onSubmit);

  useEffect(() => {
    if (!selectedParentProduct && parentProduct) {
      setSelectedParentProduct(parentProduct as unknown as ParentProductRo);
    }
  }, [parentProduct, selectedParentProduct, setSelectedParentProduct]);

  if (!parentProduct) {
    return <NoDataPlaceholder />;
  }

  return (
    <Stack
      sx={{
        backgroundColor: '#FFF',
        borderRadius: '16px',
        border: '1px solid #E6E6E6',
        p: 1,
      }}
    >
      <Stack spacing={2} data-testid="product-addons" ref={scrollRef}>
        {!parentProduct.childProductGroups?.length && <NoDataPlaceholder />}
        {parentProduct.priceCases?.map((bundle) => (
          <BundleBox key={bundle.priceCaseId} bundle={bundle} />
        ))}
        {parentProduct.childProductGroups?.map((group, index) => (
          <ProductGroupBox
            key={index}
            productGroup={group}
            error={errors?.[group.productGroupId]}
            minColumnWidth={310}
          />
        ))}
      </Stack>
      <Box
        sx={{
          p: (theme) => theme.spacing(1, 3, 3),
          textAlign: 'right',
        }}
      >
        <Button
          color="primary"
          onClick={onSubmitAddon.mutate}
          loading={onSubmitAddon.isPending}
          type="button"
          data-testid="product-addons-submit"
        >
          {t('common:next')}
        </Button>
      </Box>
    </Stack>
  );

  async function onSubmit(): Promise<void> {
    try {
      const validationErrors = await validate(selectedProducts);

      if (validationErrors) {
        scrollToRef();
        return;
      }

      const orderId = await createNewOrder();

      if (orderId && parentProduct?.offerId && parentProduct?.productId) {
        const offerId = parentProduct.offerId;
        const productId = parentProduct.productId;
        const productGroups: ProductGroup[] = mapProductGroups(
          parentProduct,
          null,
          selectedProducts,
          productId,
        );
        const offers = [
          {
            offerId,
            productGroups,
          },
        ];

        await addNewOrderOffers(orderId, offers);
        const step: Step = 'phone-number';

        router.push(
          withQueryParams(APP_ROUTES.PLANS.path, {
            orderId,
            offerId,
            productId,
            sourceId: generateSourceId(),
            step,
            referenceUrl: pathname,
            referenceStep: step,
          }),
          undefined,
          {
            shallow: true,
          },
        );
      } else {
        throw new Error(t('common:errors.unexpected'));
      }
    } catch (error) {
      throw error;
    }
  }

  async function addNewOrderOffers(
    orderId: string,
    offers: any,
  ): Promise<PostSubmitOrderRo> {
    return isAuthenticated
      ? await api.ocbDigital.order.addNewOrderOffers(tenant, {
          orderId,
          offers,
        })
      : await api.ocbDigital.orderAuthless.addNewOrderOffers(
          tenant,
          tenantId!,
          orderId,
          offers,
        );
  }

  async function createNewOrder(): Promise<string> {
    const order = isAuthenticated
      ? await api.ocbDigital.order.createNewOrder(
          tenant,
          OrderType.NEW_SUBSCRIPTION,
        )
      : await api.ocbDigital.orderAuthless.createNewAuthlessOrder(
          tenant,
          tenantId!,
        );

    return order.id;
  }
};
