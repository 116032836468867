import { useCallback, useState } from 'react';
import { AnySchema, ValidationError } from 'yup';

import { WithKeys, YupError } from '@lib/form/types';
import { GenericObject } from '@lib/types';

import { SchemaErrors, SchemaValidationResponse } from './types';

export const useSchemaValidation = (
  schema: AnySchema
): SchemaValidationResponse => {
  const [errors, setErrors] = useState<SchemaErrors | null>(null);

  const validate = useCallback(
    async (values: unknown) => {
      try {
        await schema.validate(values, {
          abortEarly: false,
        });

        setErrors(null);
        return null;
      } catch (err) {
        const { inner } = err as ValidationError;
        const mappedErrors = inner.reduce(mapErrors, {});

        setErrors(mappedErrors);

        return mappedErrors;
      }
    },
    [schema]
  );

  const reset = useCallback(() => {
    setErrors(null);
  }, []);

  return { errors, validate, reset };
};

function mapErrors(
  errors: SchemaErrors,
  { path, message, params }: YupError
): SchemaErrors {
  if (!path || !message) {
    return errors;
  }

  const key = hasKeys(message) ? message.key : message;
  const values = hasKeys(message)
    ? message.values
    : { ...(params as GenericObject) };

  return {
    ...errors,
    [path]: {
      message: {
        key,
        values,
      },
    },
  };
}

function hasKeys(obj: string | WithKeys): obj is WithKeys {
  return (obj as WithKeys).key !== undefined;
}
