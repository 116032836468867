import { RefObject, useCallback, useRef } from 'react';

interface ReturnType {
  scrollRef: RefObject<HTMLDivElement>;
  scrollToRef: () => void;
}

export const useScrollToRef = (): ReturnType => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const scrollToRef = useCallback(() => {
    scrollRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }, [scrollRef]);

  return {
    scrollRef,
    scrollToRef,
  };
};
