import { FC, useMemo } from 'react';

import { PriceCase } from '@lib/offers/types';
import { SxStyles } from '@lib/theme/sxTheme';
import { Box } from '@mui/system';
import { usePurchaseInfo } from '@templates/purchase/common/PurchaseInfoContext';
import { DefaultCard } from '@ui/cards/default-card/DefaultCard';

import { BundleCtaButton } from './BundleCtaButton';
import { ProductBox } from './ProductBox';

interface Props {
  bundle: PriceCase;
}

export const BundleBox: FC<Props> = ({ bundle }) => {
  const { selectedBundles } = usePurchaseInfo();

  const isAdded = useMemo(
    () =>
      selectedBundles.some(
        (selectedBundle) => selectedBundle.priceCaseId === bundle.priceCaseId
      ),
    [bundle.priceCaseId, selectedBundles]
  );

  return (
    <Box>
      <DefaultCard
        title={bundle.name}
        contentBackgroundColor="background.paper"
        button={
          <BundleCtaButton
            isAdded={isAdded}
            bundle={bundle}
            sx={{ padding: '4px 24px', width: 'auto' }}
          />
        }
      >
        <Box sx={styles.getValue('gridContainer')}>
          {bundle.products.map((product, index) => (
            <Box key={index}>
              <ProductBox product={product} />
            </Box>
          ))}
        </Box>
      </DefaultCard>
    </Box>
  );
};

const styles = new SxStyles({
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
    gap: (theme) => theme.spacing(2),
    gridAutoRows: '1fr',
  },
});
