import { FC } from 'react';

import { DefaultProductAddons } from './default-slice/DefaultProductAddons';
import {
  DefaultProductAddonsVariation,
  ProductAddonsVariations,
} from './types';

export interface ProductAddonsSliceProps {
  slice: ProductAddonsVariations;
}

export const ProductAddonsSlice: FC<ProductAddonsSliceProps> = (props) => {
  const { slice } = props;

  return (
    <DefaultProductAddons slice={slice as DefaultProductAddonsVariation} />
  );
};
