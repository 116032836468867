import React, { FC } from 'react';
import { useTranslation } from 'next-i18next';

import { SxTheme } from '@lib/theme/types';
import { Button } from '@ui/buttons/Button';

interface Props {
  isAdded: boolean;
  isBundleActivated: boolean;
  onAdd: () => void;
  onRemove: () => void;
  sx?: SxTheme;
}

export const CtaButton: FC<Props> = ({
  isAdded,
  isBundleActivated,
  onAdd,
  onRemove,
  sx,
}) => {
  const { t } = useTranslation();

  if (isBundleActivated) {
    return (
      <Button
        disabled
        size="small"
        fullWidth
        sx={sx}
        data-testid="addons-product-button-add-bundle"
      >
        {t('common:add')}
      </Button>
    );
  }

  if (isAdded) {
    return (
      <Button
        size="small"
        color="error"
        fullWidth
        onClick={onRemove}
        sx={sx}
        data-testid="addons-product-button-remove"
      >
        {t('common:remove')}
      </Button>
    );
  }

  return (
    <Button
      size="small"
      fullWidth
      onClick={onAdd}
      sx={sx}
      data-testid="addons-product-button-add"
    >
      {t('common:add')}
    </Button>
  );
};
