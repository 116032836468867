import { FC, useMemo } from 'react';

import { PriceCase, Product } from '@lib/offers/types';
import { SxTheme } from '@lib/theme/types';
import { usePurchaseInfo } from '@templates/purchase/common/PurchaseInfoContext';

import { CtaButton } from '../CtaButton';

interface Props {
  bundle: PriceCase;
  isAdded: boolean;
  sx?: SxTheme;
}

export const BundleCtaButton: FC<Props> = ({ bundle, isAdded, sx }) => {
  const {
    selectedBundles,
    setSelectedBundles,
    selectedProducts,
    setSelectedProducts,
  } = usePurchaseInfo();

  const isBundleActivated = useMemo(() => {
    const { products } = bundle;
    const flatSelectedBundleProducts = selectedBundles
      .filter(
        (selectedBundle) => selectedBundle.priceCaseId !== bundle.priceCaseId
      )
      .flatMap((bundle) => bundle.products);

    return products.some((product) =>
      flatSelectedBundleProducts.some(
        (selectedBundleProduct) =>
          selectedBundleProduct.productId === product.productId
      )
    );
  }, [bundle, selectedBundles]);

  return (
    <CtaButton
      isAdded={isAdded}
      isBundleActivated={isBundleActivated}
      onAdd={handleAdd}
      onRemove={handleRemove}
      sx={sx}
    />
  );

  function handleAdd(): void {
    setSelectedBundles((prev) => [...prev, bundle]);

    const updatedSelectedProducts: Record<string, Product[]> = Object.entries(
      selectedProducts
    ).reduce((acc, [productGroupId, products]) => {
      const productsNotExistingInBundle = products.filter(
        (product) =>
          !bundle.products.some(
            (bundleProduct) => bundleProduct.productId === product.productId
          )
      );

      return { ...acc, [productGroupId]: productsNotExistingInBundle };
    }, {});

    setSelectedProducts(updatedSelectedProducts);
  }

  function handleRemove(): void {
    setSelectedBundles((prev) =>
      prev.filter(
        (selectedBundle) => selectedBundle.priceCaseId !== bundle.priceCaseId
      )
    );
  }
};
