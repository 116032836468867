import { useRef } from 'react';

import { useIsOverflow } from '@lib/useIsOverflow';
import { Tooltip, Typography } from '@mui/material';

interface Props {
  name: string;
}

export const ProductName: React.FC<Props> = ({ name }) => {
  const textElementRef = useRef<HTMLElement>(null);
  const isOverflowed = useIsOverflow(textElementRef);

  return (
    <Tooltip
      title={
        <Typography fontWeight="fontWeightBold" align="left" fontSize="22px">
          {name}
        </Typography>
      }
      disableHoverListener={!isOverflowed}
    >
      <Typography
        color="primary"
        fontWeight="fontWeightBold"
        align="left"
        fontSize="22px"
        noWrap
        ref={textElementRef}
      >
        {name}
      </Typography>
    </Tooltip>
  );
};
